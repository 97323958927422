import { StyledEngineProvider, ThemeProvider, adaptV4Theme } from "@mui/material";
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Routes from "src/Routes";
import useSettings from "src/hooks/useSettings";
import { createTheme } from "src/theme";
import { loadMetaData } from "./actions/globalActions";
import Auth from "./components/Auth";
import { PostHogProvider } from "posthog-js/react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import CrispChat from "./components/CrispChat";

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
let prevPath = null;

// listen and notify Segment of client-side page updates
history.listen((location) => {
  if (location.pathname !== prevPath) {
    Sentry.setContext("path", location.pathname);
    prevPath = location.pathname;
  }
});



function App() {
  const { ui_settings } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadMetaData());
  }, [dispatch]);
  const { settings } = useSettings();
  useEffect(() => {
    if (!window.initialized && ui_settings) {
      Sentry.init({
        dsn: ui_settings.CENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(
              history
            ),
          }),
        ],
        normalizeDepth: 10,
        tracesSampleRate: 1.0,
      });
      window.initialized = true;
    }
  }, [ui_settings]);

  return (
    <PostHogProvider
      apiKey={ui_settings?.POSTHOG_KEY}
      options={{api_host: ui_settings?.POSTHOG_HOST}} >
      <Sentry.ErrorBoundary fallback={"An error has occurred"}>
        <CrispChat />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={createTheme(adaptV4Theme(settings))}>
            <StylesProvider jss={jss}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <BrowserRouter history={history} basename="/newapp">
                    <Auth>
                      <Routes />
                    </Auth>
                  </BrowserRouter>
              </LocalizationProvider>
            </StylesProvider>
          </ThemeProvider>
        </StyledEngineProvider>
        <ToastContainer
        position="bottom-left"
        theme="colored"
        draggable
        pauseOnHover
        />
      </Sentry.ErrorBoundary>
    </PostHogProvider>
  );
}

export default withTranslation()(App);
