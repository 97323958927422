import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PERMISSIONS } from 'src/config/permissions';

function CrispChat() {
  const account = useSelector((state) => state.account);
  const { ui_settings } = useSelector(state => state.global);

  useEffect(() => {
    // Only initialize Crisp when all required data is available
    if (
      account.user &&
      account.user.permissions[PERMISSIONS.CHAT_WIDGET] &&
      ui_settings?.CRISP_WEBSITE_ID
    ) {
      // Configure Crisp with the website ID
      Crisp.configure(ui_settings.CRISP_WEBSITE_ID, {
        autoload: true // Changed to true to ensure it loads
      });

      // Set user information if available
      if (account.user.email) {
        Crisp.user.setEmail(account.user.email, account.user.crisp_hash);
      }
      if (account.user.name) {
        Crisp.user.setNickname(account.user.name);
      }
    }
  }, [account.user, ui_settings]); // Re-run when these dependencies change

  return null;
}

export default CrispChat;